import {React, useContext, useEffect} from "react";
import { graphql } from "gatsby";
import EligibilityForm from "../components/EligibilityForm";
import ProgressBar from "../components/ProgressBar";

import Deadline from "../components/Deadline";
import {HeedContext} from '../gatsby-theme-carbon/components/Layout';

export default function ProfilePage({ pageContext, location, data }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "Profile", tabs: ["Profile", "Application"] },
  };
  const nodeEligibility = data.allNodeEligibility.edges.find(({node}) => !node.title.includes('SCHEMA'))
  const questions = nodeEligibility?.node.relationships.field_questions;
  const description = nodeEligibility?.node?.field_description ? nodeEligibility?.node.field_description.value : ''
  const heed = useContext(HeedContext);
  let landing = data.allNodePage.edges[0].node.body.value
  let landingtitle = data.allNodePage.edges[0].node.title;
  let title = null
  if(heed?.answers?.all_done){
    title = 'Step 3: Submit'
  }else if(heed?.user?.is_eligible){
    title = 'Step 2: Complete Application'
  }else{
    title = 'Step 1: Eligibility'
  }
  
  const { fetchUserAnswers, user } = heed;
  useEffect(() => {
    if(user.id) fetchUserAnswers();
  }, [user])

  return (
    <div className="application-wrapper profile">
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            <div className="padd txt">
              {title && <h4>{title}</h4>}
              <p>
                HEED application progress.
              </p>
            </div>
            {(heed?.user?.is_eligible || heed?.answers?.all_done) && <ProgressBar />}
            <Deadline page="profile" />
            <EligibilityForm questions={questions} description={description} />
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
        <div>
        <div id="application-title" className="padd">
        <div dangerouslySetInnerHTML={{__html: [landingtitle]}} />
          </div>
            <div  className="padd txt" dangerouslySetInnerHTML={{__html: [landing]}} />
                {/* {heed?.user?.is_eligible && <Button kind="tertiary" onClick={(e) => navigate("/edit-profile")}>Edit Profile</Button>} */}
            </div>
          </div>
      </div>
    </div>
  );
}

export const query = graphql`
query {
  allNodePage {
    edges {
      node {
        title
        body {
          value
        }
      }
    }
  }
  allNodeEligibility {
    edges {
      node {
        drupal_id
        title
        field_description {
          value
        }
        relationships {
          field_questions {
            ... on node__text_question {
              drupal_id
              title
              field_is_required
              field_identifier
              field_type
              field_number_type
              internal {
                type
              }
            }
            ... on node__checkbox_question {
              drupal_id
              title
              field_is_multiple
              field_is_required
              field_identifier
              internal {
                type
              }
              relationships {
                field_options {
                  drupal_id
                  title
                }
                field_invalid_options {
                  drupal_id
                }
              }
            }
            ... on node__formatted_text {
              drupal_id
              internal {
                type
              }
              body {
                value
              }
            }
            ... on node__address_question {
              drupal_id
              field_identifier
              field_is_required
              title
              field_exclude_fields
              internal {
                type
              }
            }
          }
        }
      }
    }
  }
}

`
